import React, { useContext } from 'react';
import './TopBar.css';
import { PiPhoneListThin, PiEnvelopeOpenThin } from 'react-icons/pi';
import { NavLink } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

const TopBar = () => {
  const { user, logout } = useContext(AuthContext);

  return (
    <div className="top-bar">
      <div className="top-bar-container">
        <div className="contact-info">
          <span>
            <a href="tel:+19058975000" className="contact-link">
              <PiPhoneListThin className="icon-adjustment" /> <span className="contact-text">(905) 897-5000</span>
            </a>
          </span>
          <span>
            <a href="/contact" className="contact-link">
              <PiEnvelopeOpenThin className="icon-adjustment" /> <span className="contact-text">info@modernsolution.ca</span>
            </a>
          </span>
        </div>
        <div className="auth-links">
          {user && (
            <>
              <span className="auth-link">Logged in as {user.username}</span>
              <button onClick={logout} className="auth-link logout-button">Logout</button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
