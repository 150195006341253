import React, { useState, useEffect } from 'react';
import './CommissionCalculator.css';

const CommissionCalculator = () => {
  const [value, setValue] = useState(1000000);

  const formatNumber = (number) => {
    return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  const updateValues = (value) => {
    const modernCommission = value * 0.01;
    const outdatedCommission = value * 0.025;
    const savings = outdatedCommission - modernCommission;

    document.getElementById('house-value-price').textContent = formatNumber(value);
    document.getElementById('other-realtors-price').textContent = formatNumber(outdatedCommission);
    document.getElementById('modern-solution-realty-price').textContent = formatNumber(modernCommission);
    document.getElementById('you-save-price').textContent = formatNumber(savings);
  };

  useEffect(() => {
    updateValues(value);
  }, [value]);

  return (
    <div className="commission-calculator-container">
      <h3 className="calculator-heading">Commission Calculator</h3>
      <div className="slider-container">
        <input 
          type="range" 
          min="500000" 
          max="5000000" 
          value={value} 
          className="slider" 
          id="priceSlider" 
          onChange={(e) => setValue(parseInt(e.target.value))}
        />
      </div>
      <div className="calculator-values">
        <div className="house-value-column value-column">
          <div className="value-title">House Value</div>
          <div id="house-value-price" className="value">{formatNumber(value)}</div>
        </div>
        <div className="traditional-brokerage-column value-column">
          <div className="value-title">Traditional Brokerage</div>
          <h5>2.5% Commission (An Outdated Commission Structure)</h5>
          <div id="other-realtors-price" className="value">{formatNumber(value * 0.025)}</div>
        </div>
        <div className="msr-column value-column">
          <div className="value-title">Modern Solution Realty</div>
          <h5 className="commission-text">1% Commission</h5>
          <div id="modern-solution-realty-price" className="value black-text">{formatNumber(value * 0.01)}</div>
        </div>
        <div className="save-column value-column">
          <div className="value-title">With <span className='partners-text'>Modern Solution Realty</span>, you save</div>
          <div id="you-save-price" className="value" style={{ color: '#9AE691' }}>{formatNumber(value * 0.015)}</div>
        </div>
      </div>
      <a href="/contact" className="list-button">Sell With Us</a>
    </div>
  );
};

export default CommissionCalculator;
