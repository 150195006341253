import React, { useEffect, useState } from 'react';
import SEO from './SEO'; // Ensure this path is correct
import Slider from 'react-slick';
import VideoPlayer from './VideoPlayer';
import GoogleReviews from './GoogleReviews';
import ContactForm from './ContactForm';
import GoogleMapComponent from './GoogleMap';
import { PiPhoneListThin, PiEnvelopeOpenThin } from 'react-icons/pi';
import image1 from '../assets/images/advertising-1.webp';
import image2 from '../assets/images/advertising-2.webp';
import image3 from '../assets/images/advertising-3.webp';
import image4 from '../assets/images/advertising-4.webp';
import image5 from '../assets/images/advertising-5.webp';
import image6 from '../assets/images/advertising-6.webp';
import Footer from './Footer'; // Import Footer component
import './Home.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ListingWidget from './ListingWidget'; // Ensure this path is correct

const Home = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 6000, // Slow down the transition speed
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Increase autoplay speed for slower transitions
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 768, // screen width 768px and below
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // screen width 480px and below
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const images = [image1, image2, image3, image4, image5, image6];

  const [openAccordion, setOpenAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );

    const elementsToAnimate = document.querySelectorAll('.animate-on-scroll');
    elementsToAnimate.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      elementsToAnimate.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  return (
    <div>
      <SEO
        title="Sell for 1% Commission | Low Commission Real Estate | 1 Percent List Realty"
        description="Sell your home for just 1% commission with Modern Solution. Enjoy low commission real estate services and get $5000 cashback when you buy. Call now!"
        keywords={[
          '1% commission real estate',
          'low commission real estate',
          'sell for 1 percent',
          '1 Percent List Realty',
          '$5000 cashback',
          'Modern Solution Realty'
        ]}
        ogImage="https://modernsolution.ca/homepage-image.jpg"
        url="https://modernsolution.ca/"
      />
      <VideoPlayer />
      <div className='google-reviews-container animate-on-scroll'>
        <GoogleReviews />
      </div>
      <div id='save-more' className='two-column-section'>
        <div className='left-column animate-on-scroll'>
          <h2 className='partners-text'>
            Sell With Us{' '}
            <span className='black-text'>and Get Your Fair Share</span>
          </h2>
          <h3 className='left-column-subtitle'>
            Use the form below to send us an email. We’ll get back to you as
            soon as we can.
          </h3>
          <div className='divider left-aligned-divider'></div>
          <div className='accordion'>
            <div className='accordion-item'>
              <div
                className='accordion-header'
                onClick={() => toggleAccordion(0)}
              >
                <div className='accordion-icon'>
                  <PiPhoneListThin
                    className='icon-adjustment-large'
                    style={{ color: '#9cdcf8' }}
                  />
                </div>
                <div className='accordion-title'>
                  <a href='tel:+19058975000' className='contact-link'>
                    (905) 897-5000
                  </a>
                </div>
                <div className='accordion-arrow'>
                  {openAccordion === 0 ? '↑' : '↓'}
                </div>
              </div>
              {openAccordion === 0 && (
                <div className='accordion-content'>
                  <p>
                    We are available 24/7. Give us a call if you have any
                    questions.
                  </p>
                </div>
              )}
            </div>
            <div className='accordion-item'>
              <div
                className='accordion-header'
                onClick={() => toggleAccordion(1)}
              >
                <div className='accordion-icon'>
                  <PiEnvelopeOpenThin
                    className='icon-adjustment-large'
                    style={{ color: '#9cdcf8' }}
                  />
                </div>
                <div className='accordion-title'>
                  <a
                    href='mailto:info@modernsolution.ca'
                    className='contact-link'
                  >
                    info@modernsolution.ca
                  </a>
                </div>
                <div className='accordion-arrow'>
                  {openAccordion === 1 ? '↑' : '↓'}
                </div>
              </div>
              {openAccordion === 1 && (
                <div className='accordion-content'>
                  <p>
                    Send us an email and one of our agents will get back to you
                    ASAP
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='right-column animate-on-scroll'>
          <h2 className='contact-form-title'>Send Us An Email.</h2>
          <div className='subcategory-divider'></div>
          <h3 className='contact-form-subtitle'>
            Use the form below to send us an email. We'll get back to you as
            soon as we can.
          </h3>
          <div className='contact-form'>
            <ContactForm />
          </div>
        </div>
      </div>


      


      
      <ListingWidget />

      <div className='advertising-partners animate-on-scroll'>
  <h2>
    Advertising <span className='partners-text'>Partners</span>
  </h2>
  <div className='divider'></div>
  <div className='partners-images'>
    {images.map((image, index) => (
      <div key={index} className='partner-image-wrapper'>
        <img src={image} alt={`partner-${index}`} className='partner-image' />
      </div>
    ))}
  </div>
</div>


      <Footer /> {/* Include Footer component */}
    </div>
  );
};

export default Home;
