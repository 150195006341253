// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import TopBar from './components/TopBar';
import Home from './components/Home';
import Buy from './components/Buy';
import Sell from './components/Sell';
import Search from './components/Search';
import Articles from './components/Articles';
import ArticleDetail from './components/ArticleDetail'; // Import the new component
import Contact from './components/Contact';
import CommissionExplained from './components/CommissionExplained';
import ThankYou from './components/ThankYou';
import SellingPage from './components/SellingPage';
import Listings from './components/Listings';
import ListingDetail from './components/ListingDetail';
import Wishlist from './components/Wishlist';
import Login from './components/Login';
import Register from './components/Register';
import Users from './components/Users';
import ForgotPassword from './components/ForgotPassword'; // Import ForgotPassword component
import useScroll from './useScroll';
import { AuthProvider } from './context/AuthContext';
import './App.css';

function App() {
  useScroll();

  return (
    <Router>
      <AuthProvider>
        <div className='App'>
          <TopBar />
          <Navbar />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/buy' element={<Buy />} />
            <Route path='/sell' element={<Sell />} />
            <Route path='/search' element={<Search />} />
            <Route path='/articles' element={<Articles />} />
            <Route path='/articles/:id' element={<ArticleDetail />} /> {/* New dynamic route */}
            <Route path='/contact' element={<Contact />} />
            <Route path='/list-for-1percent' element={<SellingPage />} />
            <Route path='/commission-explained' element={<CommissionExplained />} />
            <Route path='/thank-you' element={<ThankYou />} />
            <Route path='/listings' element={<Listings />} />
            <Route path='/listing/:ml_num' element={<ListingDetail />} />
            <Route path='/wishlist' element={<Wishlist />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/forgot-password' element={<ForgotPassword />} /> {/* Add ForgotPassword route */}
            <Route path='/users' element={<Users />} />
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
