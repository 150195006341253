import React, { useEffect, useState } from 'react';
import './VideoPlayer.css';
import { FaVolumeUp, FaRedo, FaMousePointer } from 'react-icons/fa';
import { LiaFacebookSquare, LiaInstagram } from 'react-icons/lia';

const VideoPlayer = () => {
  const [isHighQuality, setIsHighQuality] = useState(false);
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    const unmuteButton = document.getElementById('unmute-button');
    const replayButton = document.getElementById('replay-button');
    const video = document.getElementById('video-player');
    const lowQualityVideo = document.getElementById('low-quality-video');
    const gif = document.getElementById('gif-player');

    const handleUnmuteClick = () => {
      gif.style.display = 'none';
      setIsHighQuality(true);
      setIsMuted(false);
      video.currentTime = lowQualityVideo.currentTime;
      video.play();
    };

    const handleVideoEnded = () => {
      replayButton.style.display = 'block';
    };

    const handleReplayClick = () => {
      video.currentTime = 0;
      video.play();
      setIsMuted(false);
    };

    unmuteButton.addEventListener('click', handleUnmuteClick);
    video.addEventListener('ended', handleVideoEnded);
    replayButton.addEventListener('click', handleReplayClick);

    // Check if the screen width is less than 800px
    if (window.matchMedia('(max-width: 800px)').matches) {
      lowQualityVideo.style.display = 'none';
      gif.style.display = 'block';
    }

    // Add pulsate class to custom-button after 40 seconds
    const pulsateTimeout = setTimeout(() => {
      document.querySelectorAll('.custom-button').forEach((button) => {
        button.classList.add('pulsate-button');
      });
    }, 40000);

    return () => {
      unmuteButton.removeEventListener('click', handleUnmuteClick);
      video.removeEventListener('ended', handleVideoEnded);
      replayButton.removeEventListener('click', handleReplayClick);
      clearTimeout(pulsateTimeout);
    };
  }, []);

  return (
    <div className='video-player-container'>
      <div className='video-wrapper'>
        <video
          id='low-quality-video'
          playsInline
          autoPlay
          muted
          loop
          className='video-element'
          style={{ display: isHighQuality ? 'none' : 'block' }}
        >
          <source
            src={require('../assets/videos/video-compressed.mp4')}
            type='video/mp4'
          />
          Your browser does not support the video tag.
        </video>
        <video
          id='video-player'
          playsInline
          autoPlay
          muted={isMuted}
          loop={isMuted}
          className='video-element'
          style={{ display: isHighQuality ? 'block' : 'none' }}
        >
          <source
            src={require('../assets/videos/video.mp4')}
            type='video/mp4'
          />
          Your browser does not support the video tag.
        </video>
        <img
          id='gif-player'
          src={require('../assets/videos/video.gif')}
          className='gif-element'
          alt='GIF'
          style={{ display: 'none' }}
        />
        <button id='unmute-button' className='control-button'>
          <FaVolumeUp />
        </button>
        <button id='replay-button' className='control-button replay-button'>
          <FaRedo />
        </button>
      </div>
      <div className='buttons-container'>
        <a href='/sell' className='button-link'>
          <div className='button-container'>
            <p>
              Selling Your <br />
              Home
              <span className='question-mark'>?</span>
            </p>
            <div className='divider'></div>
            <div className='custom-button'>
              <div className='circle-icon'>
                <FaMousePointer />
              </div>
              List Your Home For 1%
            </div>
          </div>
        </a>
        <a href='/buy' className='button-link'>
          <div className='button-container'>
            <p>
              Buying a <br />
              Home
              <span className='question-mark'>?</span>
            </p>
            <div className='divider'></div>
            <div className='custom-button'>
              <div className='circle-icon'>
                <FaMousePointer />
              </div>
              Get $5,000 Cashback
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default VideoPlayer;
