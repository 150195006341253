// Footer.js
import React from 'react';
import { PiPhoneListThin, PiEnvelopeOpenThin } from 'react-icons/pi';
import CallToActionBottomRight from './CallToAction-BottomRight'; // Import the contact buttons componen
import './Footer.css';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
	<CallToActionBottomRight />
        <p>&copy; 2024 Modern Solution Realty. All rights reserved.</p>
        <ul className="footer-links">
          <li>
            <PiPhoneListThin className="icon" />
            <a href="tel:+19058975000" className="contact-link">(905) 897-5000</a>
          </li>
          <li>
            <PiEnvelopeOpenThin className="icon" />
            <a href="/contact" className="contact-link">info@modernsolution.ca</a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
