import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import ImageSlider from './ImageSlider';
import './Listings.css';
import AuthContext from '../context/AuthContext';
import { FaRegHeart, FaHeart } from 'react-icons/fa';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { ReactComponent as BedroomIcon } from '../assets/icons/bedroom-3.svg';
import { ReactComponent as BathroomIcon } from '../assets/icons/bathroom.svg';
import { ReactComponent as SquareFeetIcon } from '../assets/icons/squarefeet.svg';

const Listings = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [priceRange, setPriceRange] = useState({ min: 0, max: 10000000 });
  const [selectedCity, setSelectedCity] = useState('All');
  const [selectedBedrooms, setSelectedBedrooms] = useState('Any');
  const [selectedBathrooms, setSelectedBathrooms] = useState('Any');
  const [searchQuery, setSearchQuery] = useState('');
  const [hoveredListing, setHoveredListing] = useState(null);
  const [selectedListing, setSelectedListing] = useState(null);
  const [listings, setListings] = useState([]);
  const [filteredListings, setFilteredListings] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [totalListings, setTotalListings] = useState(0);
  const [initialCities, setInitialCities] = useState([]);
  const [initialBedrooms, setInitialBedrooms] = useState([]);
  const [initialBathrooms, setInitialBathrooms] = useState([]);
  const [initialZoomLevel] = useState(8); // Store the initial zoom level
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const markerClusterer = useRef(null);
  const listingsPerPage = 9;
  const maxListingsToShow = 500;
  const maxTotalListings = 10000;

  const { user } = useContext(AuthContext);

  useEffect(() => {
    const savedWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
    setWishlist(savedWishlist);
  }, []);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  useEffect(() => {
    const debouncedFetchListings = debounce(() => {
      const fetchListings = async () => {
        setLoading(true);
        try {
          // Fetch the first 500 listings for clustering
          const clusterResponse = await axios.get('https://backend.modernsolution.ca/api/listings', {
            params: {
              limit: maxListingsToShow,
              offset: 0,
              city: selectedCity !== 'All' ? selectedCity : null,
              bedrooms: selectedBedrooms !== 'Any' ? selectedBedrooms : null,
              bathrooms: selectedBathrooms !== 'Any' ? selectedBathrooms : null,
              search: searchQuery || null,
              minPrice: priceRange.min,
              maxPrice: priceRange.max,
            },
          });

          // Fetch the listings for the current page
          const pageOffset = (currentPage - 1) * listingsPerPage;
          const pageResponse = await axios.get('https://backend.modernsolution.ca/api/listings', {
            params: {
              limit: listingsPerPage,
              offset: pageOffset,
              city: selectedCity !== 'All' ? selectedCity : null,
              bedrooms: selectedBedrooms !== 'Any' ? selectedBedrooms : null,
              bathrooms: selectedBathrooms !== 'Any' ? selectedBathrooms : null,
              search: searchQuery || null,
              minPrice: priceRange.min,
              maxPrice: priceRange.max,
            },
          });

          const totalResponse = await axios.get('https://backend.modernsolution.ca/api/listings/count', {
            params: {
              city: selectedCity !== 'All' ? selectedCity : null,
              bedrooms: selectedBedrooms !== 'Any' ? selectedBedrooms : null,
              bathrooms: selectedBathrooms !== 'Any' ? selectedBathrooms : null,
              search: searchQuery || null,
              minPrice: priceRange.min,
              maxPrice: priceRange.max,
            },
          });

          const totalCount = Math.min(totalResponse.data.totalCount, maxTotalListings);
          setTotalListings(totalCount);

          setFilteredListings(pageResponse.data);
          setListings(pageResponse.data);

          if (isFirstLoad) {
            setInitialCities([
              'All',
              ...new Set(clusterResponse.data.map((listing) => listing.municipality)),
            ].sort((a, b) => a.localeCompare(b)));

            setInitialBedrooms([...Array(10).keys()].map((num) => (num + 1).toString()));

            setInitialBathrooms([...Array(10).keys()].map((num) => (num + 1).toString()));
            setIsFirstLoad(false);
          }

          // Update clusters only on first load or when filters change
          if (isFirstLoad || currentPage === 1) {
            updateClusters(clusterResponse.data);
          }

          setLoading(false);
        } catch (error) {
          console.error('Error fetching listings data:', error);
          setError(error.message);
          setLoading(false);
        }
      };

      fetchListings();
    }, 500);

    debouncedFetchListings();

    // Cleanup on unmount or when searchQuery changes
    return () => {
      clearTimeout(debouncedFetchListings);
    };
  }, [currentPage, selectedCity, selectedBedrooms, selectedBathrooms, searchQuery, priceRange]);

  useEffect(() => {
    setCurrentPage(1);
    setFilteredListings([]);

    // Reset the map's zoom level whenever filters change
    if (mapInstance.current) {
      mapInstance.current.setZoom(initialZoomLevel);
    }
  }, [selectedCity, selectedBedrooms, selectedBathrooms, searchQuery, priceRange]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: 'AIzaSyAzeCUuExS1-_9uwphZn8UKOgZZl2jBrSA',
      version: 'weekly',
      libraries: ['geometry', 'places'],
    });

    const mapStyle = [
      {
        elementType: 'geometry',
        stylers: [{ color: '#f5f5f5' }],
      },
      {
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        elementType: 'labels.text.fill',
        stylers: [{ color: '#616161' }],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#f5f5f5' }],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#bdbdbd' }],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#eeeeee' }],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: '#e5e5e5' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9e9e9e' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#d5d5d5' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#000' }],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#000' }],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [{ color: '#e5e5e5' }],
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [{ color: '#eeeeee' }],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#c9c9c9' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9e9e9e' }],
      },
    ];

    loader.load().then(() => {
      if (mapRef.current) {
        mapInstance.current = new window.google.maps.Map(mapRef.current, {
          center: { lat: 43.6532, lng: -79.3832 },
          zoom: initialZoomLevel,
          mapTypeId: 'roadmap',
          styles: mapStyle,
        });

        // Initialize clusters on the first load
        if (filteredListings.length > 0) {
          updateClusters(filteredListings.slice(0, 500));
        }
      }
    });
  }, []);

  const geocodeCache = new Map();

  const geocodeAddress = async (address, city) => {
    const fullAddress = `${address}, ${city}`;

    if (geocodeCache.has(fullAddress)) {
      return geocodeCache.get(fullAddress);
    }

    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(fullAddress)}&key=AIzaSyAzeCUuExS1-_9uwphZn8UKOgZZl2jBrSA`
      );
      const { results } = response.data;
      if (results.length > 0 && results[0].formatted_address.includes('Canada')) {
        const { lat, lng } = results[0].geometry.location;
        const position = { lat, lng };
        geocodeCache.set(fullAddress, position);
        return position;
      }
    } catch (error) {
      console.error('Error geocoding address:', fullAddress, error);
    }

    return null;
  };

  const updateClusters = async (listingsToMark) => {
    if (markerClusterer.current) {
      markerClusterer.current.clearMarkers();
      markerClusterer.current = null;
    }

    const validMarkers = [];
    const batchSize = 50;

    for (let i = 0; i < listingsToMark.length && validMarkers.length < 500; i += batchSize) {
      const batch = listingsToMark.slice(i, i + batchSize);

      const batchMarkers = await Promise.all(
        batch.map(async (listing) => {
          const { address } = listing;
          try {
            const position = await geocodeAddress(address, listing.municipality);
            if (position && !isNaN(position.lat) && !isNaN(position.lng)) {
              const marker = new window.google.maps.Marker({
                position,
                title: address,
              });
              marker.addListener('click', () => {
                setSelectedListing(listing);
              });
              return marker;
            }
          } catch (error) {
            console.error(`Failed to create marker for listing: ${address}`, error);
          }
          return null;
        })
      );

      validMarkers.push(...batchMarkers.filter(Boolean));
    }

    markerClusterer.current = new MarkerClusterer({
      map: mapInstance.current,
      markers: validMarkers,
      renderer: {
        render: ({ count, position }) => {
          const svg = window.btoa(
            `<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64">
              <circle cx="32" cy="32" r="28" fill="#9CDCF8" opacity="0.6" stroke="rgba(0, 0, 0, 0.2)" stroke-width="1"/>
              <circle cx="32" cy="32" r="22" fill="#9CDCF8" opacity="0.8" stroke="rgba(0, 0, 0, 0.5)" stroke-width="1"/>
              <circle cx="32" cy="32" r="16" fill="#9CDCF8" stroke="black" stroke-width="1"/>
              <text x="50%" y="50%" text-anchor="middle" dy=".3em" font-size="14px" font-family="Inter, sans-serif" fill="black">${count}</text>
            </svg>`
          );
          return new window.google.maps.Marker({
            position,
            icon: {
              url: `data:image/svg+xml;base64,${svg}`,
              scaledSize: new window.google.maps.Size(48, 48),
            },
          });
        },
      },
    });
  };

  const formatPrice = (price) => {
    return `$${Number(price).toLocaleString()}`;
  };

  const handleFilterChange = (setter) => (value) => {
    setter(value);
  };

  const totalPages = Math.ceil(totalListings / listingsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const maxPages = 7;
    let startPage = Math.max(1, currentPage - Math.floor(maxPages / 2));
    let endPage = startPage + maxPages - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxPages + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  const pageNumbers = getPageNumbers();

  const uniqueCities = initialCities;

  const clearFilters = () => {
    setPriceRange({ min: 0, max: 10000000 });
    setSelectedCity('All');
    setSelectedBedrooms('Any');
    setSelectedBathrooms('Any');
    setSearchQuery('');
    setCurrentPage(1);
    setFilteredListings([]);
  };

  const removeFilter = (filterType) => {
    if (filterType === 'city') {
      setSelectedCity('All');
    } else if (filterType === 'price') {
      setPriceRange({ min: 0, max: 10000000 });
    } else if (filterType === 'bedrooms') {
      setSelectedBedrooms('Any');
    } else if (filterType === 'bathrooms') {
      setSelectedBathrooms('Any');
    } else if (filterType === 'search') {
      setSearchQuery('');
    }
    setCurrentPage(1);
    setFilteredListings([]);
  };

  const handleImageClick = (e, listing) => {
    e.stopPropagation();
    if (!user && listing.sold_price) {
      return;
    }
    setSelectedListing(listing);
  };

  const toggleWishlist = (e, listingId) => {
    e.stopPropagation();
    setWishlist((prevWishlist) => {
      const updatedWishlist = prevWishlist.includes(listingId)
        ? prevWishlist.filter((id) => id !== listingId)
        : [...prevWishlist, listingId];

      localStorage.setItem('wishlist', JSON.stringify(updatedWishlist));

      return updatedWishlist;
    });
  };

  const renderImages = (listing) => {
    const photoUrls = Array.isArray(listing.photo_urls) ? listing.photo_urls : listing.photo_urls.split(',');

    if (photoUrls.length >= 3) {
      return (
        <ImageSlider
          images={[listing.main_image, ...photoUrls.slice(0, 2)]}
          onImageClick={(e) => handleImageClick(e, listing)}
          className={listing.sold_price ? (user ? 'sold user-logged-in' : 'sold') : ''}
        />
      );
    } else if (photoUrls.length > 0) {
      return <img src={listing.main_image || photoUrls[0]} alt="Listing" className="listing-image" />;
    } else {
      return <img src={listing.main_image} alt="Listing" className="listing-image" />;
    }
  };

  return (
    <div className='listing-search-container'>
      {error && (
        <div className='error-message'>
          Error fetching listings data: {error}
        </div>
      )}

      <div className='filter-bar'>
        <div className='filter-item price-range-filter'>
          <label>Price Range:</label>
          <div className="price-inputs">
            <input
              type="number"
              placeholder="Min"
              value={priceRange.min}
              onChange={(e) => handleFilterChange(setPriceRange)({ ...priceRange, min: e.target.value })}
              className="price-input"
            />
            <span> - </span>
            <input
              type="number"
              placeholder="Max"
              value={priceRange.max}
              onChange={(e) => handleFilterChange(setPriceRange)({ ...priceRange, max: e.target.value })}
              className="price-input"
            />
          </div>
        </div>
        <div className='filter-item'>
          <label>City:</label>
          <select
            value={selectedCity}
            onChange={(e) => handleFilterChange(setSelectedCity)(e.target.value)}
            className='city-select'
          >
            <option value='All'>Select City</option>
            {uniqueCities.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>
        <div className='filter-item'>
          <label>Bedrooms:</label>
          <select
            value={selectedBedrooms}
            onChange={(e) => handleFilterChange(setSelectedBedrooms)(e.target.value)}
            className='bedrooms-select'
          >
            <option value='Any'>Any</option>
            {initialBedrooms.map((bedrooms, index) => (
              <option key={index} value={bedrooms}>
                {bedrooms}
              </option>
            ))}
          </select>
        </div>
        <div className='filter-item'>
          <label>Bathrooms:</label>
          <select
            value={selectedBathrooms}
            onChange={(e) => handleFilterChange(setSelectedBathrooms)(e.target.value)}
            className='bathrooms-select'
          >
            <option value='Any'>Any</option>
            {initialBathrooms.map((bathrooms, index) => (
              <option key={index} value={bathrooms}>
                {bathrooms}
              </option>
            ))}
          </select>
        </div>
        <div className='filter-item search-filter'>
          <label>Search Address:</label>
          <input
            type='text'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder='Search listings'
            className='search-input'
          />
        </div>
      </div>
      <div className='applied-filters-bar'>
        {selectedCity !== 'All' && (
          <button
            onClick={() => removeFilter('city')}
            className='filter-button'
          >
            City: {selectedCity} <span className='remove-filter'>X</span>
          </button>
        )}
        {(priceRange.min !== 0 || priceRange.max !== 10000000) && (
          <button
            onClick={() => removeFilter('price')}
            className='filter-button'
          >
            Price: {formatPrice(priceRange.min)} - {formatPrice(priceRange.max)}{' '}
            <span className='remove-filter'>X</span>
          </button>
        )}
        {selectedBedrooms !== 'Any' && (
          <button
            onClick={() => removeFilter('bedrooms')}
            className='filter-button'
          >
            Bedrooms: {selectedBedrooms} <span className='remove-filter'>X</span>
          </button>
        )}
        {selectedBathrooms !== 'Any' && (
          <button
            onClick={() => removeFilter('bathrooms')}
            className='filter-button'
          >
            Bathrooms: {selectedBathrooms} <span className='remove-filter'>X</span>
          </button>
        )}
        {searchQuery && (
          <button
            onClick={() => removeFilter('search')}
            className='filter-button'
          >
            Search: {searchQuery} <span className='remove-filter'>X</span>
          </button>
        )}
        {(selectedCity !== 'All' ||
          priceRange.min !== 0 ||
          priceRange.max !== 10000000 ||
          selectedBedrooms !== 'Any' ||
          selectedBathrooms !== 'Any' ||
          searchQuery) && (
          <button onClick={clearFilters} className='clear-filters-button'>
            Clear All
          </button>
        )}
      </div>
      <div className='main-content'>
        <div className='map-column'>
          <div ref={mapRef} className='map-placeholder'></div>
        </div>
        <div className='listings-column'>
          <div className={`listing-grid ${isFirstLoad ? 'transition-container' : ''}`}>
            {loading ? (
              <div>Loading Listings...</div>
            ) : filteredListings.length > 0 ? (
              filteredListings.map((listing) => {
                return (
                  <div
                    key={listing.id}
                    className={`listing-card ${
                      listing.sold_price
                        ? user
                          ? 'sold user-logged-in'
                          : 'sold'
                        : ''
                    }`}
                    onClick={(e) => handleImageClick(e, listing)}
                    onMouseEnter={() =>
                      listing.sold_price !== 'Sold' && setHoveredListing(listing)
                    }
                    onMouseLeave={() =>
                      listing.sold_price !== 'Sold' && setHoveredListing(null)
                    }
                  >
                    {renderImages(listing)}

                    <div className="listing-content">
                      {user && (
                        <div className='wishlist-icon-container'>
                          {wishlist.includes(listing.id) ? (
                            <FaHeart
                              className='wishlist-icon active pop-animation'
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleWishlist(e, listing.id);
                              }}
                              onAnimationEnd={(e) =>
                                e.currentTarget.classList.remove('pop-animation')
                              }
                            />
                          ) : (
                            <FaRegHeart
                              className='wishlist-icon'
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleWishlist(e, listing.id);
                                e.currentTarget.classList.add('pop-animation');
                              }}
                              onAnimationEnd={(e) =>
                                e.currentTarget.classList.remove('pop-animation')
                              }
                            />
                          )}
                        </div>
                      )}
                      <div className='listing-style-badge'>
                        <span><a href='/contact'>Buy and Get $5000 Cash Back</a></span>
                      </div>

                      <div className='listing-details'>
                        <div className='listing-detail-item'>
                          <BedroomIcon className='icon' />
                          <span className='with-words'> {listing.bedrooms || 'N/A'}</span>
                          <span className='without-words'>{listing.bedrooms || 'N/A'}</span>
                        </div>
                        <div className='listing-detail-item'>
                          <BathroomIcon className='icon' />
                          <span className='with-words'> {listing.bathrooms || 'N/A'}</span>
                          <span className='without-words'>{listing.bathrooms || 'N/A'}</span>
                        </div>
                        <div className='listing-detail-item'>
                          SQFT: {listing.square_feet || 'N/A'}
                        </div>
                      </div>

                      <div className={`listing-title ${listing.sold_price && !user ? 'blurred' : ''}`}>
                        <div className='title-community'>
                          <h3 className='street-address'> {listing.address} {listing.unit_num ? `#${listing.unit_num}` : ''}</h3>
                          <p className='community'>
                            {listing.community}{' '}
                            <span className='separator-dot'>•</span>{' '}
                            {listing.municipality}
                          </p>
                        </div>

                        <div className='listing-price'>
                          {formatPrice(listing.price)}
                        </div>
                      </div>
                    </div>

                    {!user && listing.sold_price && (
                      <div className='login-button-overlay'>
                        <a href='/login' className='login-button'>
                          Login to View Sold Listing
                        </a>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div>No listings found for your criteria.</div>
            )}
          </div>
          <div className='pagination'>
            {currentPage > 1 && (
              <button onClick={() => paginate(currentPage - 1)}>&laquo;</button>
            )}

            {pageNumbers[0] > 1 && (
              <>
                <button onClick={() => paginate(1)}>1</button>
                <span>...</span>
              </>
            )}

            {pageNumbers.map((page) => (
              <button
                key={page}
                onClick={() => paginate(page)}
                className={`pagination-button ${
                  currentPage === page ? 'active' : ''
                }`}
              >
                {page}
              </button>
            ))}

            {pageNumbers[pageNumbers.length - 1] < totalPages && (
              <>
                <span>...</span>
                <button onClick={() => paginate(totalPages)}>
                  {totalPages}
                </button>
              </>
            )}

            {currentPage < totalPages && (
              <button onClick={() => paginate(currentPage + 1)}>&raquo;</button>
            )}
          </div>
        </div>
      </div>
      {selectedListing && (
        <>
          <div
            className='listing-popup-overlay'
            onClick={() => setSelectedListing(null)}
          ></div>
          <div className='listing-popup'>
            <button
              className='popup-close-button'
              onClick={() => setSelectedListing(null)}
            >
              X
            </button>
            <div className='popup-content'>
              <div className='listing-slider-container'>
                {renderImages(selectedListing)}
              </div>
              <div className='listing-info'>
                <h3>{selectedListing.address}</h3>
                <p>
                  {selectedListing.community}, {selectedListing.municipality}
                </p>
                <p>{formatPrice(selectedListing.price)}</p>
                <p>{selectedListing.ad_text}</p>
                <a
                  href={`/listing/${selectedListing.ml_num}`}
                  className='view-full-listing-button'
                >
                  View Full Listing
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Listings;
