import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './ListingWidget.css';
import { Link } from 'react-router-dom';

const ListingWidget = () => {
  const [listings, setListings] = useState([]);
  const sliderRef = useRef(null);
  const intervalRef = useRef(null);
  const isDraggingRef = useRef(false);
  const startXRef = useRef(0);
  const scrollLeftRef = useRef(0);

  useEffect(() => {
    localStorage.clear();

    const fetchListings = async () => {
      const mlsNumbers = [
        'C9056518',
        'W9233174',
        'W9239760',
        'W9245468',
        'W9245566',
        'S9233050',
        'W9016597',
        'W9229871',
        'W9230624',
        'E9246911',
        'N8296412',
        'N9054529',
        'C8339944',
        'E8427736',
      ];

      try {
        const response = await axios.post('https://backend.modernsolution.ca/api/listings/bulk', { mlsNumbers });
        setListings(response.data);
      } catch (error) {
        console.error('Error fetching listings:', error);
      }
    };

    fetchListings();
  }, []);

  useEffect(() => {
    if (sliderRef.current && sliderRef.current.firstChild) {
      startTicker();
    }

    return () => stopTicker();
  }, [listings]);

  const startTicker = () => {
    stopTicker(); // Clear any existing interval
    intervalRef.current = setInterval(() => {
      if (sliderRef.current) {
        sliderRef.current.scrollBy({
          left: 1,
          behavior: 'smooth',
        });

        // Check if the slider has reached the end, if so, reset to the start
        if (sliderRef.current.scrollLeft >= sliderRef.current.scrollWidth - sliderRef.current.clientWidth) {
          sliderRef.current.scrollLeft = 0;
        }
      }
    }, 20); // Adjust this value for speed (lower for faster scrolling)
  };

  const stopTicker = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const handleMouseEnter = () => {
    stopTicker();
  };

  const handleMouseLeave = () => {
    startTicker();
  };

  const scrollLeft = () => {
    stopTicker(); // Stop the ticker while manually scrolling
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -300, // Adjust this value for how much you want to scroll on each click
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    stopTicker(); // Stop the ticker while manually scrolling
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 300, // Adjust this value for how much you want to scroll on each click
        behavior: 'smooth',
      });
    }
  };

  const handleMouseDown = (e) => {
    isDraggingRef.current = true;
    sliderRef.current.classList.add('dragging');
    startXRef.current = e.pageX - sliderRef.current.offsetLeft;
    scrollLeftRef.current = sliderRef.current.scrollLeft;
  };

  const handleMouseMove = (e) => {
    if (!isDraggingRef.current) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startXRef.current) * 2; // Adjust the drag speed by multiplying the walk value
    sliderRef.current.scrollLeft = scrollLeftRef.current - walk;
  };

  const handleMouseUp = () => {
    isDraggingRef.current = false;
    sliderRef.current.classList.remove('dragging');
  };

  const handleTouchStart = (e) => {
    isDraggingRef.current = true;
    sliderRef.current.classList.add('dragging');
    startXRef.current = e.touches[0].pageX - sliderRef.current.offsetLeft;
    scrollLeftRef.current = sliderRef.current.scrollLeft;
  };

  const handleTouchMove = (e) => {
    if (!isDraggingRef.current) return;
    e.preventDefault();
    const x = e.touches[0].pageX - sliderRef.current.offsetLeft;
    const walk = (x - startXRef.current) * 2; // Adjust the drag speed by multiplying the walk value
    sliderRef.current.scrollLeft = scrollLeftRef.current - walk;
  };

  const handleTouchEnd = () => {
    isDraggingRef.current = false;
    sliderRef.current.classList.remove('dragging');
  };

  const formatPrice = (price) => {
    return `$${Number(price).toLocaleString()}`;
  };

  const filteredListings = listings.filter(listing => listing.property_type.toLowerCase() !== 'rental');

  if (!filteredListings.length) {
    return <div>Loading...</div>;
  }

  return (
    <div className='custom-listing-container'>
      <h2>Modern Solution <span className='partners-text'>Listings</span></h2>
      <div className='divider'></div>
      <div
        className='slider-wrapper'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <button onClick={scrollLeft} className='slider-button left'></button>
        <div
          className='slider'
          ref={sliderRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp} // Ensure dragging stops if the user leaves the slider
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {filteredListings.concat(filteredListings).map((listing, index) => { // Duplicate the listings for continuous scroll
            const photoUrls = listing.photo_urls ? listing.photo_urls : [];
            const firstPhotoUrl = photoUrls.length > 0 ? photoUrls[0] : '';

            return (
              <Link
                to={`/listing/${listing.ml_num}`}
                key={index} // Use index as the key since listings are duplicated
                className='custom-listing-column'
              >
                <div className='image-container'>
                  {firstPhotoUrl ? (
                    <img src={firstPhotoUrl} alt='Listing Image' />
                  ) : (
                    <p>Image not available</p>
                  )}
                </div>
                <p className='listing-info'>
                  <span className='listing-info-button'>
                    Beds {listing.bedrooms}
                  </span>
                  <span className='listing-info-button'>
                    Baths {listing.bathrooms}
                  </span>
                </p>
                <p className='listing-address'>{listing.address}</p>
                <hr className='listing-separator' />
                <div className='listing-widget-price'>
                  {formatPrice(listing.price)}
                </div>
                <Link to="/contact" className="list-button">
                  Buy and Get $5000 Cash Back
                </Link>
              </Link>
            );
          })}
        </div>
        <button onClick={scrollRight} className='slider-button right'></button>
      </div>
    </div>
  );
};

export default ListingWidget;
