import React, { useEffect } from 'react';
import './CallToAction-BottomRight.css';

const CallToActionBottomRight = () => {
  useEffect(() => {
    const handleScroll = () => {
      const contactButtons = document.getElementById('contact-buttons');
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const bottomPosition = 20 + scrollTop + 'px';
      contactButtons.style.bottom = bottomPosition;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id="contact-buttons">
      <a href="tel:9058975000" className="contact-button phone-button">
        <i className="fas fa-phone"></i>
      </a>
      <a href="/contact" className="contact-button mail-button">
        <i className="fas fa-envelope"></i>
      </a>
    </div>
  );
};

export default CallToActionBottomRight;
