import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './ListingDetail.css';
import placeholderImage from '../assets/images/browse-listings-bg.webp';
import ContactForm from './ContactForm';
import logo from '../logo.webp';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Loader } from '@googlemaps/js-api-loader';
import ListingWidget from './ListingWidget';
import AuthContext from '../context/AuthContext'; // Import AuthContext

ChartJS.register(ArcElement, Tooltip, Legend);

const ListingDetail = () => {
  const { ml_num } = useParams();
  const [listingData, setListingData] = useState({ currentListing: null, historyListings: [] });
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [coordinates, setCoordinates] = useState(null);
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const marker = useRef(null);

  const { user } = useContext(AuthContext); // Use context to check if user is logged in

  useEffect(() => {
    const fetchListing = async () => {
      try {
        const apiUrl = `https://backend.modernsolution.ca/api/listing/${ml_num}`;
        const response = await axios.get(apiUrl);
        setListingData(response.data);
        const coords = await geocodeAddress(response.data.currentListing.address);
        setCoordinates(coords);
      } catch (error) {
        console.error('Error fetching the listing data', error);
      }
    };

    fetchListing();
  }, [ml_num]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: 'AIzaSyAzeCUuExS1-_9uwphZn8UKOgZZl2jBrSA',
      version: 'weekly',
      libraries: ['geometry', 'places'],
    });

    loader.load().then(() => {
      if (mapRef.current) {
        mapInstance.current = new window.google.maps.Map(mapRef.current, {
          center: { lat: 43.6532, lng: -79.3832 }, // Center on Toronto
          zoom: 15,
          mapTypeId: 'roadmap',
        });

        if (coordinates) {
          createMarker(coordinates);
        }
      }
    });
  }, [coordinates]);

  const geocodeAddress = async (address) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=AIzaSyAzeCUuExS1-_9uwphZn8UKOgZZl2jBrSA`
      );
      const { results } = response.data;
      if (results.length > 0) {
        const { lat, lng } = results[0].geometry.location;
        return { lat, lng };
      }
    } catch (error) {
      console.error('Error geocoding address:', address, error);
    }
    return null;
  };

  const createMarker = (position) => {
    if (marker.current) {
      marker.current.setMap(null);
    }
    marker.current = new window.google.maps.Marker({
      position,
      map: mapInstance.current,
    });
    mapInstance.current.setCenter(position);
  };

  const openLightbox = (index) => {
    setIsOpen(true);
    setPhotoIndex(index);
  };

  const handlePrev = (e) => {
    e.stopPropagation();
    setPhotoIndex((photoIndex + images.length - 1) % images.length);
  };

  const handleNext = (e) => {
    e.stopPropagation();
    setPhotoIndex((photoIndex + 1) % images.length);
  };

  if (!listingData.currentListing) {
    return <div>Loading...</div>;
  }

  const { currentListing, historyListings } = listingData;

  const images = currentListing.photo_urls.length
    ? [currentListing.main_image, ...currentListing.photo_urls].slice(0, 7)
    : [placeholderImage];

  const features = currentListing.property_features
    ? currentListing.property_features.split(',').filter((feature) => feature.trim() !== '')
    : [];

  const formattedSquareFeet = currentListing.square_feet && currentListing.square_feet !== "0" ? `Approximately ${currentListing.square_feet}` : "-";

  const listingInfoLeft = [
    { label: 'PIN#', value: currentListing.pin },
    { label: 'Taxes', value: currentListing.taxes ? `$${parseFloat(currentListing.taxes).toLocaleString()}` : '' },
    { label: 'Tax Year', value: currentListing.tax_year },
    { label: 'Status', value: currentListing.status === 'A' ? 'Available' : currentListing.status },
  ].filter(info => info.value);

  const listingInfoRight = [
    { label: 'Condo Registry Office', value: currentListing.condo_registry_office },
    { label: 'Condo Corp#', value: currentListing.condo_corp_num },
    { label: 'Property Mgmt Co', value: currentListing.property_mgmt_co },
    { label: 'Maintenance', value: currentListing.maintenance ? `$${parseFloat(currentListing.maintenance).toLocaleString()}` : '' },
  ].filter(info => info.value);

  const propertyInfoLeft = [
    { label: 'Level', value: currentListing.level },
    { label: 'Square Feet', value: formattedSquareFeet },
    { label: 'Unit #', value: currentListing.unit_num },
    { label: 'Dir/Cross St', value: currentListing.cross_streets },
    { label: 'Parking Drive Spaces', value: currentListing.parking_drive_spaces },
    { label: 'Total Parking Spaces', value: currentListing.total_parking_spaces },
    { label: 'A/C', value: currentListing.cooling },
    { label: 'Laundry Features', value: currentListing.laundry_features },
    { label: 'Area', value: currentListing.area },
    { label: 'Municipality', value: currentListing.municipality },
    { label: 'Community', value: currentListing.community },
  ].filter(info => info.value);

  const propertyInfoRight = [
    { label: 'Rooms', value: currentListing.rooms },
    { label: 'Bedrooms', value: currentListing.bedrooms },
    { label: 'Washrooms', value: currentListing.washrooms },
    { label: 'Kitchens', value: currentListing.kitchens },
    { label: 'Garage Type', value: currentListing.garage_type },
    { label: 'Apt/Unit', value: currentListing.unit_num },
    { label: 'Basement', value: currentListing.basement },
    { label: 'Heating Source', value: currentListing.heating_source },
    { label: 'Heating Type', value: currentListing.heating_type },
  ].filter(info => info.value);

  const roomInfo = [
    { type: currentListing.room_1, length: currentListing.room_1_length, width: currentListing.room_1_width },
    { type: currentListing.room_2, length: currentListing.room_2_length, width: currentListing.room_2_width },
    { type: currentListing.room_3, length: currentListing.room_3_length, width: currentListing.room_3_width },
    { type: currentListing.room_4, length: currentListing.room_4_length, width: currentListing.room_4_width },
    { type: currentListing.room_5, length: currentListing.room_5_length, width: currentListing.room_5_width },
    { type: currentListing.room_6, length: currentListing.room_6_length, width: currentListing.room_6_width },
    { type: currentListing.room_7, length: currentListing.room_7_length, width: currentListing.room_7_width },
    { type: currentListing.room_8, length: currentListing.room_8_length, width: currentListing.room_8_width },
    { type: currentListing.room_9, length: currentListing.room_9_length, width: currentListing.room_9_width }
  ].filter(room => room.type && (room.length || room.width));

  const washroomInfo = [
    { type: currentListing.washrooms_type_1, level: currentListing.washrooms_type_1_level, num_pcs: currentListing.washrooms_type_1_num_pcs },
    { type: currentListing.washrooms_type_2, level: currentListing.washrooms_type_2_level, num_pcs: currentListing.washrooms_type_2_num_pcs },
    { type: currentListing.washrooms_type_3, level: currentListing.washrooms_type_3_level, num_pcs: currentListing.washrooms_type_3_num_pcs },
    { type: currentListing.washrooms_type_4, level: currentListing.washrooms_type_4_level, num_pcs: currentListing.washrooms_type_4_num_pcs },
    { type: currentListing.washrooms_type_5, level: currentListing.washrooms_type_5_level, num_pcs: currentListing.washrooms_type_5_num_pcs }
  ].filter(washroom => washroom.type && washroom.level && washroom.num_pcs);

  const directionsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(currentListing.address)}`;

  const renderListingHistoryRow = (listing, index) => {
    const isCurrentListing = index === 0;
    const isSold = !!listing.sold_price;
    const statusText = isCurrentListing
      ? 'Currently Active'
      : isSold
      ? `Sold for $${parseFloat(listing.sold_price).toLocaleString()}`
      : 'Currently Inactive';
    
    const rowClass = !user && !isCurrentListing ? 'blurred-row' : '';
  
    return (
      <tr key={listing.ml_num} className={rowClass}>
        <td>{listing.ml_num}</td>
        <td>{new Date(listing.listing_date).toLocaleDateString()}</td>
        <td>{`$${parseFloat(listing.price).toLocaleString()}`}</td>
        <td>{statusText}</td>
      </tr>
    );
  };

  const MortgageCalculatorWithChart = ({ price }) => {
    const [salePrice, setSalePrice] = useState(price);
    const [downPayment, setDownPayment] = useState(price * 0.2);
    const [downPaymentPercent, setDownPaymentPercent] = useState(20);
    const [amortizationPeriod, setAmortizationPeriod] = useState(25);
    const [interestRate, setInterestRate] = useState(4.7);

    const calculateMonthlyPayment = () => {
      const principal = salePrice - downPayment;
      const monthlyRate = interestRate / 100 / 12;
      const numberOfPayments = amortizationPeriod * 12;
      return (
        principal *
        monthlyRate *
        Math.pow(1 + monthlyRate, numberOfPayments) /
        (Math.pow(1 + monthlyRate, numberOfPayments) - 1)
      ).toFixed(2);
    };

    const resetDefaults = () => {
      setSalePrice(price);
      setDownPayment(price * 0.2);
      setDownPaymentPercent(20);
      setAmortizationPeriod(25);
      setInterestRate(6.79);
    };

    useEffect(() => {
      setDownPayment((salePrice * downPaymentPercent) / 100);
    }, [salePrice, downPaymentPercent]);

    useEffect(() => {
      setDownPaymentPercent((downPayment / salePrice) * 100);
    }, [downPayment]);

    const data = {
      labels: ['Principal', 'Interest'],
      datasets: [
        {
          data: [salePrice - downPayment, downPayment],
          backgroundColor: ['#FF6384', '#36A2EB'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB'],
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      width: 375,
      height: 375,
    };

    return (
      <div className='mortgage-calculator-container'>
        <div className='mortgage-calculator'>
          <h3 className='section-heading'>Mortgage Calculator</h3>
          <div className='calculator-field'>
            <label>Sale Price: </label>
            <div className='input-with-icon'>
              <span>$</span>
              <input
                type='number'
                value={salePrice}
                onChange={(e) => setSalePrice(Number(e.target.value))}
                className='cooper-font'
              />
            </div>
          </div>
          <div className='calculator-field-inline'>
            <div className='calculator-field'>
              <label>Down Payment: </label>
              <div className='input-with-icon'>
                <span>$</span>
                <input
                  type='number'
                  value={downPayment}
                  onChange={(e) => setDownPayment(Number(e.target.value))}
                  className='cooper-font'
                />
              </div>
            </div>
            <div className='calculator-field'>
              <label>Down Payment (%): </label>
              <div className='input-with-icon'>
                <span>%</span>
                <input
                  type='number'
                  value={downPaymentPercent}
                  onChange={(e) => setDownPaymentPercent(Number(e.target.value))}
                  className='cooper-font'
                />
              </div>
            </div>
          </div>
          <div className='calculator-field'>
            <label>Amortization Period (years): </label>
            <div className='input-with-icon'>
              <span>Yrs</span>
              <input
                type='number'
                value={amortizationPeriod}
                onChange={(e) => setAmortizationPeriod(Number(e.target.value))}
                className='cooper-font'
              />
            </div>
          </div>
          <div className='calculator-field'>
            <label>Interest Rate (%): </label>
            <div className='input-with-icon'>
              <span>%</span>
              <input
                type='number'
                step='0.01'
                value={interestRate}
                onChange={(e) => setInterestRate(Number(e.target.value))}
                className='cooper-font'
              />
            </div>
          </div>
          <button onClick={resetDefaults} className='full-width-button cooper-font'>Reset</button>
        </div>
        <div className='mortgage-pie-chart'>
          <div style={{ width: '375px', height: '375px' }}>
            <Pie data={data} options={options} />
          </div>
          <div className='monthly-payment'>
            <h4 className='section-heading'>Monthly Payment: ${calculateMonthlyPayment()}</h4>
          </div>
          <a href={`/contact?property=${encodeURIComponent(currentListing.address)}`} className='property-action-button'>
            Buy and get $5000 cash back
          </a>
        </div>
      </div>
    );
  };

  return (
    <div className='listing-detail-container'>
      <div className={`listing-detail-content ${!user && currentListing.sold_price !== '' ? 'left-column-blurred' : ''}`}>
        {!user && currentListing.sold_price !== '' && (
          <div className='login-register-overlay'>
            <a href='/login' className='login-register-button'>Login</a>
            <a href='/register' className='login-register-button'>Register</a>
          </div>
        )}
        
        <div className='desktop-gallery'>
          <div className='grid-container'>
            <div className='hero-image image-wrapper' onClick={() => openLightbox(0)}>
              <img src={currentListing.main_image || placeholderImage} alt='Main Image' />
              <div className='image-overlay'></div>
            </div>
            {images.slice(1).map((url, index) => (
              <div className='small-image image-wrapper' key={index}>
                <img
                  src={url || placeholderImage}
                  alt={`Gallery Image ${index + 1}`}
                  onClick={() => openLightbox(index + 1)}
                />
                <div className='image-overlay'></div>
              </div>
            ))}
          </div>
        </div>

        <div className='mobile-gallery'>
          <div className='hero-image image-wrapper' onClick={() => openLightbox(0)}>
            <img src={currentListing.main_image || placeholderImage} alt='Main Image' />
            <div className='image-overlay'></div>
            <button className='image-nav prev' onClick={handlePrev}>&lt;</button>
            <button className='image-nav next' onClick={handleNext}>&gt;</button>
          </div>
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)}
          />
        )}

        <div className='address-container'>
          <h1 className='address-heading'>{currentListing.address}</h1>
          <div className='mls-number' style={{ color: 'grey', fontSize: '14px' }}>
            MLS#: {currentListing.ml_num}
          </div>
        </div>

        <div className='property-info' style={{ marginTop: '20px' }}>
          <div className='price'>
            {currentListing.property_type.toLowerCase() === 'rental'
              ? `$${parseFloat(currentListing.price).toLocaleString()}/month`
              : `$${parseFloat(currentListing.price).toLocaleString()}`}
          </div>
        </div>

        <div className='details'>
          <div className='info-item icon-text-container bedrooms'>
            <img
              src='https://adrianc517.sg-host.com/wp-content/uploads/2024/06/bedroom.svg'
              alt='Bedrooms'
              className='icon'
            />
            <span className="with-words">{currentListing.bedrooms} Bedrooms</span>
            <span className="without-words">{currentListing.bedrooms}</span>
          </div>
          <div className='info-item icon-text-container bathrooms'>
            <img
              src='https://adrianc517.sg-host.com/wp-content/uploads/2024/06/bathroom.svg'
              alt='Bathrooms'
              className='icon'
            />
            <span className="with-words">{currentListing.bathrooms} Bathrooms</span>
            <span className="without-words">{currentListing.bathrooms}</span>
          </div>
          <div className='info-item text-container'>
            <span>SQFT: {formattedSquareFeet}</span>
          </div>
        </div>

        <div className='property-info-separator'></div>

        <div className='main-content' style={{ display: 'flex', marginTop: '20px' }}>
          <div className='left-column' style={{ flex: '70%', backgroundColor: 'white', paddingTop: '0' }}>
            <h3 className='section-heading'>Links</h3>
            <div className='link-buttons'>
              <a href={directionsUrl} className='property-action-button' target='_blank' rel='noopener noreferrer'>
                Directions
              </a>
              {currentListing.virtual_tour && (
                <a href={currentListing.virtual_tour} className='property-action-button' target='_blank' rel='noopener noreferrer'>
                  Virtual Tour
                </a>
              )}
            </div>

            <h3 className='section-heading'>Listing History</h3>
            <table className='listing-history-table'>
              <thead>
                <tr>
                  <th>MLS Number</th>
                  <th>Listing Date</th>
                  <th>Listing Price</th>
                  <th>Sold For</th>
                </tr>
              </thead>
              <tbody>
                {[currentListing, ...historyListings].map(renderListingHistoryRow)}
              </tbody>
            </table>
            {!user && historyListings.length > 0 && (
              <p>
                Login <span className='partners-text'><a href={`/login?redirect=${window.location.pathname}`}>here</a></span> with your existing account or Register <span className='partners-text'><a href={`/register?redirect=${window.location.pathname}`}>here</a></span> a new account to view listing history.
              </p>
            )}

            {/* Conditionally render the Nearby Area section based on coordinates */}
        {coordinates && (
      <>
        <h3 className='section-heading'>Nearby Area</h3>
        <div ref={mapRef} className='google-map'></div>
      </>
    )}

            <h3 className='section-heading'>About This Property</h3>
            <p className='description'>{currentListing.ad_text}</p>

            {features.length > 0 && (
              <>
                <h3 className='section-heading'>Features</h3>
                <div className='grid-template-features'>
                  {features.map((feature, index) => (
                    <div className='feature' key={index}>
                      <p>{feature}</p>
                    </div>
                  ))}
                </div>
              </>
            )}

            {listingInfoLeft.length > 0 && listingInfoRight.length > 0 && (
              <>
                <h3 className='section-heading'>Listing Information</h3>
                <div className='info-table-container'>
                  <table className='info-table'>
                    <tbody>
                      {listingInfoLeft.map((info, index) => (
                        <tr key={index}>
                          <td><strong>{info.label}</strong></td>
                          <td>{info.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <table className='info-table'>
                    <tbody>
                      {listingInfoRight.map((info, index) => (
                        <tr key={index}>
                          <td><strong>{info.label}</strong></td>
                          <td>{info.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}

            {propertyInfoLeft.length > 0 && propertyInfoRight.length > 0 && (
              <>
                <h3 className='section-heading'>Property Information</h3>
                <div className='info-table-container'>
                  <table className='info-table'>
                    <tbody>
                      {propertyInfoLeft.map((info, index) => (
                        <tr key={index}>
                          <td><strong>{info.label}</strong></td>
                          <td>{info.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <table className='info-table'>
                    <tbody>
                      {propertyInfoRight.map((info, index) => (
                        <tr key={index}>
                          <td><strong>{info.label}</strong></td>
                          <td>{info.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}

            {roomInfo.length > 0 && (
              <>
                <h3 className='section-heading'>Room Information</h3>
                <table className='info-table'>
                  <thead>
                    <tr>
                      <th>Room Type</th>
                      <th>Length</th>
                      <th>Width</th>
                    </tr>
                  </thead>
                  <tbody>
                    {roomInfo.map((room, index) => (
                      <tr key={index}>
                        <td>{room.type}</td>
                        <td>{room.length} m</td>
                        <td>{room.width} m</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}

            {washroomInfo.length > 0 && (
              <>
                <h3 className='section-heading'>Washroom Information</h3>
                <table className='info-table'>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Level</th>
                      <th>Number of Pieces</th>
                    </tr>
                  </thead>
                  <tbody>
                    {washroomInfo.map((washroom, index) => (
                      <tr key={index}>
                        <td>{washroom.type}</td>
                        <td>{washroom.level}</td>
                        <td>{washroom.num_pcs}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}

            {currentListing.extras && (
              <>
                <h3 className='section-heading'>Extras</h3>
                <p>{currentListing.extras}</p>
              </>
            )}

            <MortgageCalculatorWithChart price={currentListing.price} />

          </div>

          <div className='right-column' style={{ flex: '25%', marginLeft: '0px', backgroundColor: '#f0f0f0', paddingTop: '20px', position: 'sticky', top: '0' }}>
            {currentListing.realtor === 'MODERN SOLUTION REALTY INC.' && (
              <img src={logo} alt='Modern Solution Realty Inc.' style={{ marginBottom: '10px' }} />
            )}
            <p className='brokerage-info' style={{ fontWeight: currentListing.realtor === 'MODERN SOLUTION REALTY INC.' ? 'bold' : 'normal' }}>
              {currentListing.realtor}
            </p>
            <div className='property-action-box'>
              <p>Interested in this property? One of our agents would be happy to help you!</p>
              <a href={`/contact?property=${encodeURIComponent(currentListing.address)}`} className='property-action-button'>
                Buy and get $5000 cash back
              </a>
            </div>
            <div className='property-action-box'>
              <p>Looking for more details about this property? Contact one of our agents and we will be happy to help.</p>
              <a href='tel:9058975000' className='property-action-button'>
                Contact Agent
              </a>
            </div>
            <div className='contact-form-box'>
              <ContactForm defaultMessage={`I am interested in buying ${currentListing.address}`} />
            </div>
          </div>
        </div>

        <ListingWidget />
        <div className='bottom-bar'>
          <button className='action-button' style={{ backgroundColor: '#EF3E5E', color: 'white' }}>
            <a href={`/contact?property=${encodeURIComponent(currentListing.address)}`}>Buy and get $5000 Cash Back</a>
          </button>
          <button className='action-button' style={{ backgroundColor: '#398EDD', color: 'white' }}>
            <a href='/contact'>Contact Agent</a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListingDetail;
